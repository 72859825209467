import { useMediaQuery } from "@mui/material";
import React from "react";
import { Chart } from "react-google-charts";
import useLocationStore from "../../stores/locationStore";


const Cuisines = function Cuisines() {
  const { locations } = useLocationStore();
  const cuisines = locations.map((location) => location.cuisine);

  const getData = () => {
    // Function to count cuisines and format data for the chart
    const countCuisines = (data) => {
      const cuisineCounts = data.reduce((counts, cuisine) => {
        const newCounts = { ...counts };
        newCounts[cuisine] = (newCounts[cuisine] || 0) + 1;
        return newCounts;
      }, {});

      // Convert counts into an array of arrays for the chart
      const chartData = [["Cuisine", "Count"]];
      Object.keys(cuisineCounts).forEach((cuisine) => {
        if (Object.prototype.hasOwnProperty.call(cuisineCounts, cuisine)) {
          chartData.push([cuisine, cuisineCounts[cuisine]]);
        }
      });

      return chartData;
    };

    const formattedChartData = countCuisines(cuisines);
    return formattedChartData;
  };

  const isDesktop = useMediaQuery("(min-width:960px)");

  const options = {
    title: "Cuisines by Gastro event",
    pieHole: 0.4,
    is3D: true,
  };

   const mobileOptions = {
     title: "Cuisines by Gastro event",
     legend: "none"
   };

  return isDesktop ? (
    <Chart
      chartType="PieChart"
      data={getData()}
      width="100%"
      height="500px"
      options={options}
      legendToggle
    />
  ) : (
    <Chart
      chartType="BarChart"
      data={getData()}
      width="100%"
      height="400px"
      options={mobileOptions}
      legendToggle
    />
  );
};

export default Cuisines;
