export default function getPerson(id) {
  const gastros = JSON.parse(sessionStorage.getItem("gastro-storage"));
  const {allGastros} = gastros.state;

  const singleGastro = allGastros.find((person) => person.uid === id);

  if(singleGastro){
  const {uid, name, profileBadge} = singleGastro;

  return {uid, name, profileBadge};
  }
    return null;

};
