import React, { useCallback } from "react";
import Proptypes from "prop-types";
import { Box, Paper, Grid, Avatar, Typography, Button } from "@mui/material";
import Linkify from "linkify-react";
import useGastroStore from "../stores/gastroStore";

const ChatMessage = function ChatMessage({
    item,
    handleDeleteMessage,
}) {

  const { LiUid } = useGastroStore();
  const convertDate = useCallback((timestamp) => {
    const date = new Date(timestamp);
    const timePart = date.toTimeString().slice(0, 5);
    const datePart = date.toLocaleDateString("en-GB");
    return `${timePart} ${datePart}`;
  }, []);

    const linkProps = {
        target: '_blank',
        rel: 'noopener noreferrer',
    };

  return (
    <Grid
      item
      key={item.timestamp}
      style={{
        display: "flex",
        justifyContent: LiUid === item.id ? "flex-start" : "flex-end",
        maxHeight: "150px",
      }}
    >
      <Paper
        elevation={4}
        style={{
          padding: 8,
          backgroundColor: "#fff",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Avatar
              alt={item.username}
              src={item.userimage}
              sx={{ width: 56, height: 56 }}
              style={{ marginRight: 8 }}
            />
            <Typography variant="body1" color="#000">
              <Linkify options={{ attributes: linkProps }}>
                {item.body}
              </Linkify>
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" border="0px solid red" justifyContent="space-between">
          <Typography variant="body2" color="#757575">
            {convertDate(item.timestamp) || null}
          </Typography>
          {item.id === LiUid && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleDeleteMessage(item.chatId)}
              style={{ alignSelf: "center", minWidth: "25px", height: "25px", padding: "1px", marginLeft: "8px" }}
            >
              X
            </Button>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};


ChatMessage.propTypes = {
item: Proptypes.shape({
    timestamp: Proptypes.string.isRequired,
    id: Proptypes.string.isRequired,
    username: Proptypes.string.isRequired,
    userimage: Proptypes.string.isRequired,
    body: Proptypes.string.isRequired,
    chatId: Proptypes.string.isRequired,
}).isRequired,
handleDeleteMessage: Proptypes.func.isRequired,
};

export default ChatMessage;
