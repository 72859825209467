import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

// Define your store
const useSettingsStore = create(
  persist(
    (set) => ({
        risk: false,
        chat: false,
        setRisk: (data) => set(() => ({ risk: data })),
        setChat: (data) => set(() => ({ chat: data })),
    }),
    {
      name: "settings-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useSettingsStore;
