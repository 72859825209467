import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import Proptypes from 'prop-types';
import { Box } from '@mui/material';
import { createUseStyles } from '../styles';
import Property from '../assets/Property';
import PropertyGrid from '../assets/PropertyGrid';

import members from '../services/members';

const useCardStyles = createUseStyles(() => ({
  cardContainer: {
    backgroundColor: "transparent",
    width: "300px",
    perspective: "1000px" /* Remove this if you don't want the 3D effect */,
    "&$cardFlip $cardInner": {
      transform: "rotateY(180deg)",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardInner: {
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center",
    transition: "transform 0.8s",
    transformStyle: "preserve-3d",
  },
  cardFront: {
    backgroundColor: "#000",
    color: "black",
    position: "absolute",
    width: "100%",
    height: "100%",
    WebkitBackfaceVisibility: "hidden" /* Safari */,
    backfaceVisibility: "hidden",
  },
  cardBack: {
    backgroundColor: "#ffbf46",
    color: "#000",
    transform: "rotateY(180deg)",
    position: "absolute",
    width: "100%",
    height: "100%",
    padding: "20px",
    WebkitBackfaceVisibility: "hidden" /* Safari */,
    backfaceVisibility: "hidden",
  },

  cardImage: {
    padding: 0,
    width: "100%",
    height: "100%",
    borderRadius: "20%",
  },

  cardFlip: {},
}));

const ProfileCard = function ProfileCard({
  title,
  imageLink,
  memberId,
}) {
  const classes = useCardStyles();
  const [flip, setFlip] = useState(false);
  const membersRecord = members.find((person) => person.memberId === memberId);

  const {
    nickname,
    strength,
    portionSize,
    taste,
    planning,
    banter,
    flair,
    specialMove,
  } = membersRecord.profile;

  const calcTotal = useMemo(() => {
    if (membersRecord) {
      const combinedScore = (strength + portionSize + taste + planning + banter + flair);
      return Math.round((combinedScore / 6));
    }
    return null;
  }, [strength, portionSize, taste, planning, banter, flair, membersRecord]);

  return (
    <Box
      className={classNames(classes.cardContainer, {
        [classes.cardFlip]: flip,
      })}
      onClick={() => setFlip(!flip)}
    >
      <Box className={classes.cardInner}>
        <Box className={classes.cardFront}>
          <Box
            component="img"
            className={classes.cardImage}
            src={imageLink}
            title={title}
            alt=""
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "#ffbf46",
            color: "#000",
            transform: "rotateY(180deg)",
            position: "absolute",
            width: "100%",
            height: "100%",
            WebkitBackfaceVisibility: "hidden" /* Safari */,
            backfaceVisibility: "hidden",
            padding: "0 10px",
          }}
        >
          <PropertyGrid header="Vital Statistics">
            <Property name="Nickname" value={nickname} />
            <Property name="Strength" value={strength} />
            <Property name="Portion Size" value={portionSize} />
            <Property name="Taste" value={taste} />
            <Property name="Planning" value={planning} />
            <Property name="Banter" value={banter} />
            <Property name="Flair" value={flair} />
            <Property name="Meal Count" value={membersRecord.mealCount} />
            <Property name="Special Move" value={specialMove} />
            <Property name="Overall Rating" value={calcTotal} />
          </PropertyGrid>
        </Box>
      </Box>
    </Box>
  );
};

ProfileCard.defaultProps = {
  title: 'Gastronaut',
  imageLink: 'https://www.w3schools.com/howto/img_avatar.png',
  memberId: "",
};

ProfileCard.propTypes = {
  title: Proptypes.string,
  imageLink: Proptypes.string,
  memberId: Proptypes.string,
};

export default ProfileCard;
