import React, { useState } from "react";
import {
  Link, useHistory, useParams,
} from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  TextField,
  Avatar,
} from "@mui/material";
import ReactStars from "react-rating-stars-component";
import { updateVenueDesc, updateVenueRating } from "../services/firebase";
import members from '../services/members';
import Page from '../layouts/Page';
import getPerson from "../services/getPerson";
import useLocationStore from "../stores/locationStore";


const Venue = function Venue() {
  const [venueDescription, setVenueDescription] = useState('');
  const [tempDescr, setTempDescr] = useState('');
  const [tempRating, setTempRating] = useState(0);

  const { locationId } = useParams();

  const history = useHistory();
  const {locations} = useLocationStore();
  const venues = locations;

  const locationRecord = venues.find((venue) => venue.locationId === locationId);

  const {
    name, mapLink, cuisine, memberId, description, attendees, hero, date, rating
  } = locationRecord;
  const membersRecord = members.find((person) => person.memberId === memberId);
  const { profileBadge, fullName } = membersRecord;
    const gastroData = JSON.parse(
      sessionStorage.getItem("gastro-storage")
    ).state;
    const { LiUid } = gastroData;
  const loggedInMemberId = LiUid;
  const canEdit = loggedInMemberId === memberId;

  return (
    <Page>
      <Box>
        <Box m={2} p={4} backgroundColor="#ffbf46">
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "30px", sm: "40px" },
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "18px", sm: "20px" },
            }}
          >
            {cuisine}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "14px", sm: "16px" },
            }}
          >
            {date}
          </Typography>
        </Box>
        <Box
          backgroundColor="rgba(255,255,255,1)"
          maxWidth="1446px"
          padding={2}
          margin={2}
        >
          <Box>
            <Box
              display="flex"
              flexWrap="wrap"
              mt={2}
              justifyContent="space-between"
            >
              <Box width={{ xs: "100%", md: "45%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </Button>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={{ xs: "column", md: "row" }}
                  marginBottom="20px"
                >
                  <Box maxWidth="85px" display="flex" alignItems="center">
                    <Link to="/members">
                      <Box
                        component="img"
                        sx={{
                          border: `2px solid #757575`,
                          borderRadius: "12px",
                          position: "relative",
                          width: "80px",
                          height: "80px",
                          textAlign: "center",
                          transition: "transform .2s",
                          margin: "20px 5px",
                          display: "flex",
                          "&:hover": {
                            transform: "scale(1.2)",
                          },
                        }}
                        src={profileBadge}
                        title={fullName}
                        alt={fullName}
                      />
                    </Link>
                  </Box>
                  {hero && (
                    <Box
                      component="img"
                      sx={{
                        border: `2px solid #757575`,
                        borderRadius: "12px",
                        position: "relative",
                        maxHeight: "250px",
                        maxWidth: "400px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                      src={`/images/locations/${hero}`}
                      title={fullName}
                      alt={fullName}
                    />
                  )}
                </Box>
                {attendees && attendees.length > 0 && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                        textDecoration: "underline",
                      }}
                    >
                      Attendees
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      mb={2}
                    >
                      {attendees.map((attendee) => {
                        const personRec = getPerson(attendee);
                        return (
                          <Avatar
                            key={personRec.uid}
                            alt={personRec.name}
                            src={personRec.profileBadge}
                            sx={{
                              marginBottom: "10px",
                              marginRight: "10px",
                              border: "1px solid #757575",
                              height: 50,
                              width: 50,
                            }}
                          />
                        );
                      })}
                    </Box>
                  </>
                )}

                {rating && (
                  <Box mb={2} display="flex" alignItems="center">
                    <Typography variant="body1" sx={{mr: 2}}>Gastro-rating: </Typography>
                    <ReactStars
                      count={5}
                      edit={false}
                      value={rating}
                      size={24}
                      activeColor="#ffd700"
                      isHalf
                      emptyIcon={<i className="far fa-star" />}
                      halfIcon={<i className="fa fa-star-half-alt" />}
                      fullIcon={<i className="fa fa-star" />}
                    />
                  </Box>
                )}

                {!description && !tempDescr && canEdit ? (
                  <Box display="flex" flexDirection="column">
                    <Typography
                      style={{ marginBottom: "12px" }}
                      variant="body1"
                    >
                      Looks like you picked this place, so please add a review
                      or a description!
                    </Typography>{" "}
                    <Box mb={2}>
                      <ReactStars
                        count={5}
                        onChange={(e) => {
                          setTempRating(e);
                          updateVenueRating(e, locationId);
                        }}
                        value={tempRating}
                        size={24}
                        activeColor="#ffd700"
                        isHalf
                        emptyIcon={<i className="far fa-star" />}
                        halfIcon={<i className="fa fa-star-half-alt" />}
                        fullIcon={<i className="fa fa-star" />}
                      />
                    </Box>
                    <TextField
                      id="standard-required"
                      label="Description"
                      variant="outlined"
                      multiline
                      onChange={(option) =>
                        setVenueDescription(option.target.value)
                      }
                      value={venueDescription}
                    />
                    <Button
                      variant="contained"
                      onClick={() => {
                        setTempDescr(venueDescription);
                        updateVenueDesc(venueDescription, locationId);
                      }}
                      disabled={venueDescription.length === 0}
                      style={{
                        margin: "12px 0 12px 0",
                      }}
                      color="secondary"
                    >
                      Add a description
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    <Typography sx={{ mt: 2, mb: 2 }} variant="body1">
                      {tempDescr && !description ? tempDescr : description}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box width="550px" height="100%" padding={0}>
                <iframe
                  title="map"
                  style={{
                    border: `1px solid #ffbf46`,
                    width: "100%",
                    height: "550px", // 100%
                    padding: "0px",
                  }}
                  src={`${mapLink}`}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default Venue;
