import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  getFirestore,
  query,
  collection,
  where,
  onSnapshot,
} from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import Page from '../layouts/Page';
import {
  sendPasswordReset,
  auth,
} from "../services/firebase";
import members from '../services/members';
import GodModeOptions from '../components/GodModeOptions';

const emailRegex = /\S+@\S+\.\S+/;

const Settings = function Settings() {
  const [email, setEmail] = useState('');
  const [godMode, setGodMode] = useState(false);
  const [user] = useAuthState(auth);
  const [emailError, setEmailError] = useState(false);
  const [success, setSuccess] = useState(false);

  // Loads data and listens for upcoming ones.
  const loadData = useCallback((val) => {
    // Create the query to fetch and listen for new ones.
    const settingsUser = query(collection(getFirestore(), 'users'), where('email', '==', val));

    // Start listening to the query.
    onSnapshot(settingsUser, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
          const data = change.doc.data();
          setGodMode(data.godMode);
      });
    });
  }, []);

  useMemo(() => {
    loadData(user.email);
  }, [ loadData, user.email]);

  const checkIsValidEmail = (value) => {
    const re = /\S+@\S+\.\S+/;
    const validEmail = re.test(value);
    if (!validEmail && value.length > 0) {
      setEmailError(true);
      return validEmail;
    }
    setEmailError(false);
    sendPasswordReset(email);
    return validEmail;
  };

  const onSuccess = (val) => {
    console.log('=== val Settings.jsx [142] ===', val);
    setSuccess(val);
  }

  return (
    <Page>
      <Box m={{ xs: 1, sm: 4 }} p={4} backgroundColor="#ffbf46">
        <Typography
          variant="h2"
          letterSpacing="1px"
          fontWeight={400}
          m="0 auto"
          sx={{
            fontSize: { xs: "24px", sm: "40px" },
          }}
        >
          Settings
        </Typography>
      </Box>
      <Box
        p={{ xs: 1, sm: 4 }}
        m={{ xs: 1, sm: 4 }}
        sx={{
          minHeight: "500px",
          backgroundImage: "url(/images/backgrounds/bg-three.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#000",
        }}
      >
        <Box>
          <Box
            p={{ xs: 1, sm: 4 }}
            sx={{
              backgroundColor: "#fff",
              width: { xs: "100%", sm: "50%" },
              display: "flex",
              flexDirection: "column",
              margin: "0 auto",
              borderRadius: "10px",
              border: "4px solid #000",
            }}
          >
            {success && (
              <Snackbar open={success} autoHideDuration={1000}>
                <Alert severity="success" onClose={() => setSuccess(false)}>
                  {" "}
                  Successfully saved!
                </Alert>
              </Snackbar>
            )}
            <Typography variant="h6" fontWeight={700} mb={4}>
              Reset password
            </Typography>
            <TextField
              id="standard-required"
              label="Enter email"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              sx={{ mb: 2 }}
              helperText={emailError && "Invalid email address"}
              error={emailError}
            />
            <Button
              variant="contained"
              onClick={() => checkIsValidEmail(email)}
              disabled={
                !email ||
                email.length < 2 ||
                emailError ||
                !emailRegex.test(email)
              }
              size="large"
              fullWidth
              sx={{ mb: 6 }}
            >
              Send password reset email
            </Button>
            {godMode && (
              <GodModeOptions members={members} successfullyAdded={(val) => onSuccess(val)} />
            )}
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default Settings;
