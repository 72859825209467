import React from "react";
import { Box } from "@mui/material";
import Page from "../layouts/Page";
import { Cuisines, Visits, VisitsByYear } from "../components/stats";


const Stats = function Stats() {
  return (
    <Page>
      <Box backgroundColor="rgba(255,255,255,0.5)" m={{ md: 4 }} p={{ md: 4 }}>
        <Box maxWidth="1446px" display="flex" flexWrap="wrap">
          <Box
            width={1}
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            p="20px"
            flexWrap="wrap"
          >
            <Cuisines />
            <Visits />
            <VisitsByYear />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default Stats;
