import React, { useEffect, useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import { Chart } from "react-google-charts";
import members from "../../services/members";

const Visits = function Visits() {

const chartData = useMemo(() => [["Hosts", "Number of Gastros"]], []);

 useEffect(() => {
    members.map((member) => chartData.push([member.fullName, member.mealCount]));
 }, [chartData]);

   const isDesktop = useMediaQuery("(min-width:960px)");

  const options = {
    title: "Successful hosts by Gastro",
    pieHole: 0.4,
    is3D: true,
  };

  const mobileOptions = {
    title: "Successful hosts by Gastro",
    legend: "none",
  };

  return isDesktop ? (
    <Chart
      chartType="PieChart"
      data={chartData}
      width="100%"
      height="500px"
      options={options}
      legendToggle
    />
  ) : (
    <Chart
      chartType="BarChart"
      data={chartData}
      width="100%"
      height="400px"
      options={mobileOptions}
      legendToggle
    />
  );
};

export default Visits;
