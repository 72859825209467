import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import _ from "lodash";
import {
  fetchVenues,
  fetchGastro,
  fetchAllGastros,
} from "../services/firebase";
import Page from "../layouts/Page";
import useLocationStore from "../stores/locationStore";
import useGastroStore from "../stores/gastroStore";
import RunningOrder from "../components/RunningOrder";

const Main = function Main() {
  const userEmail = useGastroStore().UserEmail || null;
  const [activeGastro, setActiveGastro] = useState([]);
  const [loadingAG, setLoadingAG] = useState(true);
  const [loggedInGastro, setLoggedInGastro] = useState([]);
  const [liGastroLoading, setLiGastroLoading] = useState(true);
  const [allGastrosLoading, setAllGastrosLoading] = useState(true);
  const [allSortedGastros, setAllSortedGastros] = useState([]);
  const { setLocations } = useLocationStore();
  const {
    setAllGastros,
    setLiUid,
    setLiEmail,
    setLiUserName,
    setLiSelected,
    setLiProfileBadge,
    setSgEmail,
    setSgName,
    setSgProfileBadge,
    setSgSelected,
    setSgUid,
    setSgSubmittedPlan,
    LiUid,
  } = useGastroStore();

  const loading =
    loadingAG || liGastroLoading || allGastrosLoading;

  useEffect(() => {
    fetchGastro()
      .then((data) => {
        setActiveGastro(data[0]);
        setSgEmail(data[0].email);
        setSgName(data[0].name);
        setSgProfileBadge(data[0].profileBadge);
        setSgSelected(data[0].selected);
        setSgUid(data[0].uid);
        setSgSubmittedPlan(data[0].submittedPlan);
        setLoadingAG(false);
      })
      .catch((error) => {
        console.error(new Error(error));
      });

      fetchVenues()
        .then((data) => {
          setLocations(data);
        })
        .catch((error) => {
          console.error(error);
        });

    fetchGastro(userEmail).then((data) => {
      setLoggedInGastro(data[0]);
      setLiUid(data[0].uid);
      setLiEmail(data[0].email);
      setLiUserName(data[0].name);
      setLiSelected(data[0].selected);
      setLiProfileBadge(data[0].profileBadge);
      setLiGastroLoading(false);
    });

    fetchAllGastros()
      .then((data) => {
        setAllGastros(data);
        setAllGastrosLoading(false);
        const sortedGastros = _.sortBy(data, "runningOrder");
        setAllSortedGastros(sortedGastros);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [
    setAllGastros,
    setLiEmail,
    setLiProfileBadge,
    setLiSelected,
    setLiUid,
    setLiUserName,
    setLocations,
    setSgEmail,
    setSgName,
    setSgProfileBadge,
    setSgSelected,
    setSgSubmittedPlan,
    setSgUid,
    userEmail,
  ]);

  const username = _.get(loggedInGastro, "name", "");
  const part = username.toString().split(" ");
  const firstName = part[0];


  const getUserBadge = useMemo(() => {
    switch (LiUid) {
      case "GM758108":
      case "GM245547":
        return "images/badges/dcfc.png"; // me  // garf
      case "GM774541":
        return "images/badges/lfc.png"; // pat
      case "GM007007":
        return "images/badges/walsall.png"; // pete
      case "GM662233":
        return ""; // tom
      case "GM453007":
        return "images/badges/arsenal.png"; // andy
      case "GM413670":
        return "images/badges/brum.png"; // tarps
      case "GM125488":
        return ""; // bryman
      case "GM991568":
        return "images/badges/palace.png"; // brown
      case "GM649712":
        return ""; // cadman
      case "GM442876":
        return "images/badges/everton.png"; // chris
      default:
        return "";
    }

  }, [LiUid]);

  const hasBadge = getUserBadge !== "";

  return (
    <Page>
      <Box m={{ xs: 1, sm: 4 }} p={4} backgroundColor="#FFBF46" display="flex">
        <Typography
          variant="h2"
          letterSpacing="1px"
          fontWeight={400}
          m="0 auto"
          sx={{
            fontSize: { xs: "24px", sm: "40px" },
          }}
        >
          Welcome to Gastronauts, {firstName}
        </Typography>
        {
          hasBadge &&
        <Box component="img" src={getUserBadge} alt="user badge"
          sx={{
            objectFit: "contain",
            width: { xs: "50px", sm: "100px" },
            height: { xs: "50px", sm: "100px" },
          }} />
        }
      </Box>
      <Box
        p={4}
        m={{ xs: 1, sm: 4 }}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
        }}
      >
        <Typography variant="h6" fontWeight={700} mb={4}>
          The current baton-wielding Gastro is... {activeGastro.name}{" "}
        </Typography>
        <Typography variant="body1" fontWeight={400} mb={4}>
          Running order
        </Typography>
        <Box display="flex" justifyContent="center">
          {loading ? (
            <CircularProgress />
          ) : (
            <Suspense fallback={<CircularProgress />}>
              <RunningOrder allSortedGastros={allSortedGastros} activeGastro={activeGastro}/>
            </Suspense>
          )}
        </Box>
        {activeGastro.email === userEmail ? (
          <Box>
            <Typography variant="body1">
              So then, it is your turn to choose a venue. You can do this by
              heading over to the <Link to="/Planning">Planning tool</Link>
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="body1">
              There may be a plan afoot! You can check this by heading over to
              the <Link to="/Planning">Planning tool</Link> to cast your vote.
            </Typography>
          </Box>
        )}
        <br />
        <Typography variant="body1">
          If you are reading this, then Congratulations!
        </Typography>
        <br />
        <Typography variant="body1">
          You are part of a very special group of fellas that like to enjoy good
          food, good company and good times!
        </Typography>
        <br />
        <Typography variant="body1">
          The Gastronauts were conceived quite naturally one June evening in
          2014 when fate slammed together two stellar groups of chaps whilst
          they were going about their gentlemanly businesses.
        </Typography>
        <br />
        <Typography variant="body1">
          Whence it was realised that they were both within beverage-pounding
          proximity to each other, a decision was made to collaborate and put an
          end to the bothersome ailments of hunger and consciousness. Thus, upon
          the hallowed earth within the Spice Lounge, a manly agreement was made
          to perpetuate our doings and the Gastronauts were birthed!
        </Typography>
        <br />
      </Box>
    </Page>
  );
};

export default Main;
