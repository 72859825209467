const membersCard = [
  {
    memberId: "GM758108",
    fullName: "Simon",
    cardImage: "/images/members/sicard.png",
  },
  {
    memberId: "GM453007",
    fullName: "Andy",
    cardImage: "/images/members/andycard.png",
  },
  {
    memberId: "GM662233",
    fullName: "Tom",
    cardImage: "/images/members/tomcard.png",
  },
  {
    memberId: "GM649712",
    fullName: "Lee C",
    cardImage: "/images/members/cadmancard.png",
  },
  {
    memberId: "GM125488",
    fullName: "Lee Bry",
    cardImage: "/images/members/brymancard2.png",
  },
  {
    memberId: "GM991568",
    fullName: "Brown",
    cardImage: "/images/members/browncard.png",
  },
  {
    memberId: "GM245547",
    fullName: "Garf",
    cardImage: "/images/members/garfcard.png",
  },
  {
    memberId: "GM007007",
    fullName: "Pete",
    cardImage: "/images/members/petecard.png",
  },
  {
    memberId: "GM774541",
    fullName: "Pat",
    cardImage: "/images/members/patcard.png",
  },
  {
    memberId: "GM413670",
    fullName: "Tarps",
    cardImage: "/images/members/tarpcard.png",
  },
  {
    memberId: "GM442876",
    fullName: "Chris",
    cardImage: "/images/members/chriscard.png",
  },
];

export default membersCard;
