import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

// Define your store
const useGastroStore = create(
  persist(
    (set) => ({
      allGastros: "",
      LiUid: "",
      LiEmail: "",
      LiUserName: "",
      LiSelected: "",
      LiProfileBadge: "",
      SgEmail: "",
      SgName: "",
      SgProfileBadge: "",
      SgSelected: "",
      SgUid: "",
      SgSubmittedPlan: "",
      UserEmail: "",

      setAllGastros: (data) => set(() => ({ allGastros: data })),
      setLiUid: (data) => set(() => ({ LiUid: data })),
      setLiEmail: (data) => set(() => ({ LiEmail: data })),
      setLiUserName: (data) => set(() => ({ LiUserName: data })),
      setLiSelected: (data) => set(() => ({ LiSelected: data })),
      setLiProfileBadge: (data) => set(() => ({ LiProfileBadge: data })),
      setSgEmail: (data) => set(() => ({ SgEmail: data })),
      setSgName: (data) => set(() => ({ SgName: data })),
      setSgProfileBadge: (data) => set(() => ({ SgProfileBadge: data })),
      setSgSelected: (data) => set(() => ({ SgSelected: data })),
      setSgUid: (data) => set(() => ({ SgUid: data })),
      setSgSubmittedPlan: (data) => set(() => ({ SgSubmittedPlan: data })),
      setUserEmail: (data) => set(() => ({ UserEmail: data })),
    }),
    {
      name: "gastro-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useGastroStore;
