import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    TextField,
} from "@mui/material";
import { addVenue } from "../services/firebase";
import useGastroStore from "../stores/gastroStore";


const AddVenueForm = function AddVenueForm({
    successfullyAdded
}) {
    const defaultVenueState = {
      attendees: [],
      cuisine: "",
      date: "",
      description: "",
      hero: "",
      name: "",
      locationId: "",
      logo: "",
      mapLink: "",
      memberId: "",
      isTransparent: false,
      menuLink: "",
    };
    const [loading, setLoading] = useState(false);
    const [venueState, setVenueState] = useState(defaultVenueState);
    const { allGastros } = useGastroStore();

     const sortedGastros = allGastros.sort(
       (a, b) => a.runningOrder - b.runningOrder
     );

    const addVenueRec = () => {
        console.log(venueState);
        setLoading(true);
        addVenue(venueState).then(() => {
        successfullyAdded(true);
        setVenueState(defaultVenueState);
        setLoading(false);
       });
    };

    const customStyles = {
    selectedMenuItem: {
        backgroundColor: "green", // Customize the background color
        color: "white", // Customize the text color
    },
    };

    return (
      <>
        <Typography variant="h6" sx={{ mt: 4 }}>
          Add Venue
        </Typography>
        <TextField
          id="standard-required"
          label="Name"
          variant="outlined"
          onChange={(option) =>
            setVenueState((updatedState) => ({
              ...updatedState,
              name: option.target.value,
            }))
          }
          value={venueState.name}
          sx={{ my: 2 }}
        />
        <TextField
          id="standard-required"
          label="Description"
          variant="outlined"
          onChange={(option) =>
            setVenueState((updatedState) => ({
              ...updatedState,
              description: option.target.value,
            }))
          }
          value={venueState.description}
          sx={{ my: 2 }}
        />
        <TextField
          id="standard-required"
          label="Cuisine"
          variant="outlined"
          onChange={(option) =>
            setVenueState((updatedState) => ({
              ...updatedState,
              cuisine: option.target.value,
            }))
          }
          value={venueState.cuisine}
          sx={{ my: 2 }}
        />
        <FormControl fullWidth>
          <InputLabel id="active-mmeberId">Member ID</InputLabel>
          <Select
            labelId="memberId-select"
            id="memberId-select"
            value={venueState.memberId}
            label="Member ID"
            onChange={(option) =>
              setVenueState((updatedState) => ({
                ...updatedState,
                memberId: option.target.value,
              }))
            }
          >
            {sortedGastros.map((member) => (
              <MenuItem key={member.uid} value={member.uid}>
                {member.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Select
          id="isTransparent"
          label="isTransparent"
          variant="outlined"
          options={["True", "False"]}
          onChange={(option) =>
            setVenueState((updatedState) => ({
              ...updatedState,
              isTransparent: option.target.value,
            }))
          }
          value={venueState.isTransparent}
          sx={{ my: 2 }}
        >
          <MenuItem value>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </Select>
        <TextField
          id="locationId"
          label="Location ID"
          variant="outlined"
          onChange={(option) =>
            setVenueState((updatedState) => ({
              ...updatedState,
              locationId: option.target.value,
            }))
          }
          value={venueState.locationId}
          sx={{ my: 2 }}
        />
        <TextField
          id="logo"
          label="Logo"
          variant="outlined"
          onChange={(option) =>
            setVenueState((updatedState) => ({
              ...updatedState,
              logo: option.target.value,
            }))
          }
          value={venueState.logo}
          sx={{ my: 2 }}
        />
        <TextField
          id="mapLink"
          label="Map link"
          variant="outlined"
          onChange={(option) =>
            setVenueState((updatedState) => ({
              ...updatedState,
              mapLink: option.target.value,
            }))
          }
          value={venueState.mapLink}
          sx={{ my: 2 }}
        />
        <FormControl fullWidth>
          <InputLabel id="active-attendees">Attendees</InputLabel>
          <Select
            labelId="attendees-select"
            id="attendees-select"
            value={venueState.attendees}
            label="Attendees"
            multiple
            onChange={(option) =>
              setVenueState((updatedState) => ({
                ...updatedState,
                attendees: option.target.value,
              }))
            }
          >
            {sortedGastros.map((member) => (
              <MenuItem
                key={member.uid}
                value={member.uid}
                style={
                  venueState.attendees.includes(member.uid)
                    ? customStyles.selectedMenuItem
                    : {}
                }
              >
                {member.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="hero"
          label="Hero"
          variant="outlined"
          onChange={(option) =>
            setVenueState((updatedState) => ({
              ...updatedState,
              hero: option.target.value,
            }))
          }
          value={venueState.hero}
          sx={{ my: 2 }}
        />
        <TextField
          id="date"
          label="Date"
          variant="outlined"
          onChange={(option) =>
            setVenueState((updatedState) => ({
              ...updatedState,
              date: option.target.value,
            }))
          }
          value={venueState.date}
          sx={{ my: 2 }}
        />
        <TextField
          id="menuLink"
          label="Menu Link"
          variant="outlined"
          onChange={(option) =>
            setVenueState((updatedState) => ({
              ...updatedState,
              menuLink: option.target.value,
            }))
          }
          value={venueState.menuLink}
          sx={{ my: 2 }}
        />

        <Button
          variant="contained"
          onClick={() => addVenueRec()}
          // disabled={!selected}
          size="large"
          sx={{ mt: 2 }}
          loading={loading.toString()}
          endIcon={
            loading && (
              <CircularProgress filled size={20} sx={{ color: "#febf13" }} />
            )
          }
        >
          Add
        </Button>
      </>
    );
};

AddVenueForm.propTypes = {
  successfullyAdded: PropTypes.func.isRequired,
};

export default AddVenueForm;
