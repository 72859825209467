import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import { Button, Chip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { nanoid } from 'nanoid';
import useGastroStore from '../stores/gastroStore';

const DateRowVote = function DateRowVote({
  value,
  existingVotes,
  disabled,
  onChange,
  onDelete,
}) {

  const [votes, setVotes] = useState([]);

  const { LiUid, LiProfileBadge } = useGastroStore();


  useEffect(() => {
    onChange(votes, existingVotes);
  }, [votes, existingVotes, onChange]);

  const voteFor = () => {
    setVotes((oldArray) => [
      ...oldArray,
      {
        date: value,
        profileBadge: LiProfileBadge,
        uid: LiUid,
        voteId: nanoid(),
      },
    ]);
  };

  const removeVote = (val) => {
    setVotes(votes.filter((rec) => val === rec.uid));
    onDelete(votes);
  };

  return (
    <Box>
      <Box mt={4}>
        <Typography variant="h6">{value}</Typography>
      </Box>
      <Button
        variant="contained"
        onClick={() => {
          voteFor(value);
        }}
        disabled={votes.length > 0 || disabled}
        sx={{ width: { xs: "100%", sm: "120px" }, marginBottom: "12px" }}
      >
        Vote
      </Button>

      {existingVotes.map((vote) => {
        if (vote.date === value) {
          return (
            <Chip
              key={vote.voteId}
              sx={{
                marginLeft: "10px",
                marginBottom: "12px",
              }}
              color="success"
              onDelete={() => removeVote(vote)}
              avatar={<Avatar src={vote.profileBadge} />}
              disabled
            />
          );
        }
        return null;
      })}

      {votes.map((vote) => (
        <Chip
          key={vote.uid}
          sx={{
            marginLeft: "10px",
            marginBottom: "12px",
          }}
          color="success"
          onDelete={() => removeVote(vote)}
          avatar={<Avatar src={LiProfileBadge} />}
          disabled={disabled}
        />
      ))}
    </Box>
  );
};

DateRowVote.defaultProps = {
  disabled: false,
  onChange: () => { },
  onDelete: () => { },
  existingVotes: [],
};

DateRowVote.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  existingVotes: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    profileBadge: PropTypes.string,
    uid: PropTypes.string,
    voteId: PropTypes.string,
  })),
};

export default DateRowVote;
