import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

// Define your store
const useLocationStore = create(
  persist(
    (set) => ({
      locations: "",
      setLocations: (data) => set(() => ({ locations: data })),
    }),
    {
      name: "location-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);


export default useLocationStore;
