import React from "react";
import {
  Box,
} from "@mui/material";
import Page from "../layouts/Page";



const Risk = function Risk() {

  return (
    <Page>
      <Box backgroundColor="rgba(255,255,255,0.5)" m={{ md: 4 }} p={{ md: 4 }}>
        <Box maxWidth="1446px" display="flex" flexWrap="wrap">

   TEST
        </Box>
      </Box>
    </Page>
  );
};

export default Risk;
