import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory , Link } from "react-router-dom";
import { sendPasswordResetEmail } from 'firebase/auth';
import { Box, Button } from "@mui/material";
import { auth } from '../services/firebase';


function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading] = useAuthState(auth);
  const history = useHistory();
  useEffect(() => {
    if (loading) return;
    if (user) history.replace("/main");
  }, [user, loading, history]);
  return (
    <div className="reset">
      <div className="reset__container">
        <input
          type="text"
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <Button
          className="reset__btn"
          onClick={() => sendPasswordResetEmail(email)}
        >
          Send password reset email
        </Button>
        <Box>
          Don&apos;t have an account? <Link to="/register">Register</Link> now.
        </Box>
      </div>
    </div>
  );
}
export default Reset;
