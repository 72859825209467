import React from "react";
import PropTypes from 'prop-types';
import { Box } from "@mui/material";

const RunningOrder = function RunningOrder({
    allSortedGastros,
    activeGastro,
}){
const { name, email, profileBadge } = activeGastro;

return(
    <Box display="flex" flexWrap="wrap">
        {allSortedGastros.map((gastro) => {
            const isActiveGastro = gastro.email === email;
            return isActiveGastro ? (
                <Box
                    key={gastro.uid}
                    component="img"
                    alt="Gastronaut Profile"
                    src={profileBadge}
                    title={name}
                    width="120px"
                    height="120px"
                    border="4px solid #ffbf46"
                    borderRadius="12px"
                    mb={2}
                    sx={{
                        transition: "transform .2s",
                        "&:hover": {
                        transform: "scale(1.3)",
                        borderColor: "#ffbf46",
                        },
                        zIndex: 2,
                    }}
                />
            ) : (
                <Box
                    key={gastro.uid}
                    sx={{
                        position: "relative",
                        "::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        backgroundColor: "rgba(128, 128, 128, 0.6)",
                        zIndex: 1,
                        borderRadius: "12px",
                        width: "80px",
                        height: "80px",
                        },
                    }}
                >
                    <Box
                        component="img"
                        alt="Gastronaut Profile"
                        src={gastro.profileBadge}
                        title={gastro.name}
                        width="80px"
                        height="80px"
                        border="2px solid grey"
                        borderRadius="12px"
                        mb={2}
                        sx={{
                        userSelect: "none",
                        cursor: "not-allowed",
                        }}
                    />
                </Box>
            );
        })}
    </Box>
)
};

RunningOrder.propTypes = {
  allSortedGastros: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
      profileBadge: PropTypes.string,
      uid: PropTypes.string,
    })
  ).isRequired,
  activeGastro: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    profileBadge: PropTypes.string,
  }).isRequired,
};

export default RunningOrder;
